<template lang="pug">
  .calendar-explanation-popover
    ExplanationContent(
      :items="items"
      :explanation-type="explanationType"
    )
</template>

<script>
  import { EXPLANATION_TYPE_ITEMS } from "./constants"
  import { isAutoSetPrices, currentMode } from "@/helpers/price-calendar"

  export default {
    components: {
      ExplanationContent: () => import("./ExplanationContent")
    },

    computed: {
      currentMode,

      explanationType({ currentMode }) {
        return isAutoSetPrices() ? `${currentMode}_autoset` : currentMode
      },

      items({ explanationType }) {
        // values of EXPLANATION_TYPE_ITEMS must be a function to display data in current locale
        return EXPLANATION_TYPE_ITEMS[explanationType]()
      }
    }
  }
</script>

<style lang="sass" scoped>
  .calendar-explanation-popover
    font-size: 0.8rem
</style>
