import { i18n } from "@/config"

export const MANUAL_MODE_CALENDAR_FIELDS = () => [
  {
    number: 1,
    className: "car-class-name",
    label: "溝の口駅前",
    description: i18n.t("price_calendar.calendar_explanation.car_class")
  },
  {
    number: 2,
    className: "rental-period",
    label: i18n.t("price_calendar.filters.rental_periods.day1"),
    description: i18n.t("price_calendar.calendar_explanation.rental_period")
  },
  {
    number: 3,
    className: "date",
    label: 24,
    description: i18n.t("price_calendar.calendar_explanation.start_date")
  },
  {
    number: 4,
    className: "weekday",
    label: i18n.t("datepicker.weekdays_min.thursday"),
    description: i18n.t("price_calendar.calendar_explanation.weekday")
  },
  {
    number: 5,
    className: "booked",
    label: 5,
    description: i18n.t("price_calendar.calendar_explanation.booked")
  },
  {
    number: 6,
    className: "available",
    label: 14,
    description: i18n.t("price_calendar.calendar_explanation.available")
  },
  {
    number: 7,
    className: "current-price",
    label: 2000,
    description: i18n.t("price_calendar.calendar_explanation.current_price")
  }
]

export const AUTO_PRICE_MODE_CALENDAR_FIELDS = () => [
  {
    number: 1,
    className: "car-class-name",
    label: "溝の口駅前",
    description: i18n.t("price_calendar.calendar_explanation.car_class")
  },
  {
    number: 2,
    className: "rental-period",
    label: i18n.t("price_calendar.filters.rental_periods.day1"),
    description: i18n.t("price_calendar.calendar_explanation.rental_period")
  },
  {
    number: 3,
    className: "date",
    label: 24,
    description: i18n.t("price_calendar.calendar_explanation.start_date")
  },
  {
    number: 4,
    className: "weekday",
    label: i18n.t("datepicker.weekdays_min.thursday"),
    description: i18n.t("price_calendar.calendar_explanation.weekday")
  },
  {
    number: 5,
    className: "booked",
    label: 5,
    description: i18n.t("price_calendar.calendar_explanation.booked")
  },
  {
    number: 6,
    className: "available",
    label: 14,
    description: i18n.t("price_calendar.calendar_explanation.available")
  },
  {
    number: 7,
    className: "rank",
    label: "A",
    description: i18n.t("price_calendar.calendar_explanation.rank")
  },
  {
    number: 8,
    className: "current-price",
    label: i18n.n(2800),
    description: i18n.t("price_calendar.calendar_explanation.current_price")
  },
  {
    number: 9,
    className: "calculated-price",
    label: i18n.n(2000),
    description: i18n.t("price_calendar.calendar_explanation.calculated_price")
  },
  {
    number: 10,
    className: "applying-price",
    label: 2000,
    description: i18n.t("price_calendar.calendar_explanation.applying_price")
  }
]

export const AI_MODE_CALENDAR_FIELDS = () => {
  const items = AUTO_PRICE_MODE_CALENDAR_FIELDS()
  // in AI mode calendar has not `rank` so need to remove its item
  items.splice(6, 1)
  return items
}

export const AUTO_PRICE_MODE_AUTOSET_CALENDAR_FIELDS = () => [
  {
    number: 1,
    className: "car-class-name",
    label: "溝の口駅前",
    description: i18n.t("price_calendar.calendar_explanation.car_class")
  },
  {
    number: 2,
    className: "rental-period",
    label: i18n.t("price_calendar.filters.rental_periods.day1"),
    description: i18n.t("price_calendar.calendar_explanation.rental_period")
  },
  {
    number: 3,
    className: "date",
    label: 24,
    description: i18n.t("price_calendar.calendar_explanation.start_date")
  },
  {
    number: 4,
    className: "weekday",
    label: i18n.t("datepicker.weekdays_min.thursday"),
    description: i18n.t("price_calendar.calendar_explanation.weekday")
  },
  {
    number: 5,
    className: "booked",
    label: 5,
    description: i18n.t("price_calendar.calendar_explanation.booked")
  },
  {
    number: 6,
    className: "available",
    label: 14,
    description: i18n.t("price_calendar.calendar_explanation.available")
  },
  {
    number: 7,
    className: "rank",
    label: "A",
    description: i18n.t("price_calendar.calendar_explanation.rank")
  },
  {
    number: 8,
    className: "current-price",
    label: 2000,
    description: i18n.t("price_calendar.calendar_explanation.current_price")
  }
]

export const AI_MODE_AUTOSET_CALENDAR_FIELDS = () => {
  const items = AUTO_PRICE_MODE_AUTOSET_CALENDAR_FIELDS()
  // in AI mode calendar has not `rank` so need to remove its item
  items.splice(6, 1)
  return items
}

export const EXPLANATION_TYPE_ITEMS = {
  manual_mode: MANUAL_MODE_CALENDAR_FIELDS,
  auto_price_mode: AUTO_PRICE_MODE_CALENDAR_FIELDS,
  ai_mode: AI_MODE_CALENDAR_FIELDS,
  auto_price_mode_autoset: AUTO_PRICE_MODE_AUTOSET_CALENDAR_FIELDS,
  ai_mode_autoset: AI_MODE_AUTOSET_CALENDAR_FIELDS
}
